import { render, staticRenderFns } from "./AssetTransfer.vue?vue&type=template&id=43b28ca3&"
import script from "./AssetTransfer.vue?vue&type=script&lang=ts&"
export * from "./AssetTransfer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/workspace/SPP/spp-erp-fe-staging/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('43b28ca3')) {
      api.createRecord('43b28ca3', component.options)
    } else {
      api.reload('43b28ca3', component.options)
    }
    module.hot.accept("./AssetTransfer.vue?vue&type=template&id=43b28ca3&", function () {
      api.rerender('43b28ca3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/assets/Transactions/AssetTransfer.vue"
export default component.exports